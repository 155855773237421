/*globals $ */

export function ajax(options) {
    var { data, endPoint, success, ...rest } = options; // eslint-disable-line no-unused-vars

    var url = 'http://dev.textedit.svsantos.com:3000/api';
    url = 'https://textedit.svsantos.com/api';

    $.ajax({
        url: url + endPoint,
        data: JSON.stringify(data),
        type: 'POST',
        contentType: 'application/json; charset=UTF-8',
        dataType: 'json',
        success,
        ...rest,
    });
}
