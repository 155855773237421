/**

Markdown Rules Currently in Effect

 x | Block #  |                              Rule
---|----------|----------------------------------------------------------------
 x | Block 1  | Replace escape chars =>  * ~ ^ ` ( ) | ?
 x | Block 2  | Replace `.` and lines beginning with 4 spaces with code blocks
 x | Block 3  | Join adjacent code blocks together
 x | Block 4  | Replace > with quote block
 x | Block 5  | Replace #{1,6} with h1-h6
 x | Block 6  | Make []() and text links into actual links
 x | Block 7  | Replace ***** with a line
 x | Block 8  | Replace **.** (bold), *.* (italics), ~~.~~ (strikethrough), ~.~ (highlight), __.__ (underline)
 x | Block 9  | Replace ^. with superscript
 x | Block 10 | Replace table format with table
 x | Block 11 | Not sure
 x | Block 12 | Creates paragraphs around the rest of the text
 x | Block 13 | Gets rid of excess <p> tags
 x | Block 14 | Replaces -,*,+ with unordered list
 x | Block 15 | Replaces double space at end of line with \n

*/

export function markdown_format(text) {
    if(!text) {
        return text;
    }
    var i, j;

    // -- Block 1 -- //
    text = markdown_escapeHtml(text); // escape characters
    text = text.replace(/\\\*/g, "&#42;"); // *
    text = text.replace(/\\~/g, "&#126;"); // ~
    text = text.replace(/\\\^/g, "&#94;"); // ^
    text = text.replace(/\\`/g, "&#96;"); // `
    text = text.replace(/\\\(/g, "&#40;"); // (
    text = text.replace(/\\\)/g, "&#41;"); // )
    text = text.replace(/\\\|/g, "&#124;"); // |
    text = text.replace(/\\\?/g, "&#63;"); // ?
    text = text.replace(/\\\./g, "&#46;"); // .
    text = text.replace(/\\-/g, "&#45;"); // -
    text = text.replace(/\\\+/g, "&#43;"); // +
    text = text.replace(/\\_/g, "&#95;"); // _

    // -- Block 2 -- //
    text = text.replace(/`(.*)`/g, "<code>$1</code>"); // replaces `.` with code
    var regex = /(\n) {4}(.*)(\n)/g;
    while(regex.test(text)) {
        text = text.replace(regex, "$1<pre><code>$2</code></pre>$3"); // replaces 4 spaces with code
    }
    text = text.replace(/<\/code><\/pre>(\s*)<pre><code>/g, "$1"); // joins adjacent code blocks together

    // -- Block 15 -- //
    text = text.replace(/ {2}(\n)/g, "$1$1"); // replaces double space at end of line with \n

    // -- Block 3 -- //
    var codeEscapeRegex = [
        [/<code>((?:(?!<code>)(?:.|\n))*)\*((?:(?!<code>)(?:.|\n))*)<\/code>/g, "<code>$1&#42;$2</code>"]
        , [/<code>((?:(?!<code>)(?:.|\n))*)~((?:(?!<code>)(?:.|\n))*)<\/code>/g, "<code>$1&#126;$2</code>"]
        , [/<code>((?:(?!<code>)(?:.|\n))*)\^((?:(?!<code>)(?:.|\n))*)<\/code>/g, "<code>$1&#94;$2</code>"]
        , [/<code>((?:(?!<code>)(?:.|\n))*)\n((?:(?!<code>)(?:.|\n))*)<\/code>/g, function(match) {return match.replace(/\n/g, "<br/>");}]
        , [/<code>((?:(?!<code>)(?:.|\n))*)\.((?:(?!<code>)(?:.|\n))*)<\/code>/g, "<code>$1&#46;$2</code>"]
    ];
    for(i = 0; i < codeEscapeRegex.length; i++) { // escapes all other formatting inside <code> tags
        while(codeEscapeRegex[i][0].test(text)) {
            text = text.replace(codeEscapeRegex[i][0], codeEscapeRegex[i][1]);
        }
    }

    // -- Block 4 -- //
    regex = /(\n)>(.*)(\n)/g;
    while(regex.test(text)) {
        text = text.replace(/(\n)>(.*)(\n)/g, "$1<blockquote>$2</blockquote>$3"); // replaces >. with quote
    }
    regex = /<blockquote>>(.*)<\/blockquote>/g;
    while(regex.test(text)) {
        text = text.replace(/<blockquote>>(.*)<\/blockquote>/g, "<blockquote><blockquote>$1</blockquote></blockquote>"); // replaces >>. with nested quote
    }
    regex = /<\/blockquote>((?:\s|<br\/>)*)<blockquote>/g;
    while(regex.test(text)) {
        text = text.replace(/<\/blockquote>((?:\s|<br\/>)*)<blockquote>/g, function(match, p1) {return p1.replace(/\n/g, "<br/>");}); // joins adjacent quotes blocks together
    }

    // -- Block 5 -- //
    for(i = 6; i >= 1; i--) {
        regex = new RegExp("(^|\\n)\\#{" + i + "}(.*)(\\n)", "g");
        while(regex.test(text)) {
            text = text.replace(regex, "$1<h" + i + ">$2</h" + i + ">$3"); // replaces #. with header
        }
    }

    // -- Block 6 -- //
    text = text.replace(/\[(.+)\]\(([^\s]+)\)/g, function(match, p1, p2){ // replaces []() with link
        var ret = "<a href='" + p2.replace(/\./g, "&#46;") + "' class='lato'>" + p1.replace(/\./g, "&#46;") + "</a>"; // changes periods so the same link won't get caught in next replace
        return ret;
    });
    text = text.replace(/((?:(?:https?):\/\/)?(?:www\.)?[a-zA-Z0-9\-.]{3,}\.[a-zA-Z]{2,}\/?[^\s]*)(?!'|")/g, function(match, p1){ // replaces link with link
        var link = (/https?:\/\//.test(p1) ? p1 : "http://" + p1);
        var ret = "<a href='" + link + "' class='lato'>" + p1 + "</a>";
        return ret;
    });

    // -- Block 7 -- //
    //text = text.replace(/(\n)\*{5,}(\n)/g, "$1<hr/>$2"); // replaces ***** with line
    regex = /(\n)\*{5,} *(\n)/g;
    while(regex.test(text)) {
        text = text.replace(/(\n)\*{5,} *(\n)/g, "$1<hr/>$2"); // replaces ***** with line
    }

    // -- Block 8 -- //
    text = text.replace(/\*\*([^*\n]+)\*\*/g, "<b>$1</b>"); // replaces **.** with bold
    text = text.replace(/\*([^*\n]+)\*/g, "<i>$1</i>"); // replaces *.* with italics
    text = text.replace(/~~([^~\n]+)~~/g, "<s>$1</s>"); // replaces ~~.~~ with strikethrough
    text = text.replace(/~([^~\n]+)~/g, "<mark>$1</mark>"); // replaces ~.~ with highlight
    text = text.replace(/__([^_\n]+)__/g, "<ins>$1</ins>"); // replaces __.__ with underline

    // -- Block 9 -- // // add parentisis to allow spaces
    regex = /\^([\w^_]*)\b/g;
    while(regex.test(text)) {
        text = text.replace(/\^([\w^_]*)\b/g, "<sup>$1</sup>"); // replaces ^. with superscript
    }
    regex = /_([\w^_]*)\b/g;
    while(regex.test(text)) {
        text = text.replace(/_([\w^_]*)\b/g, "<sub>$1</sub>"); // replaces _. with subscript
    }

    // -- Block 10 -- //
    text = text.replace(/(^|\n)(?:(?:(?:[^\n|]+\|)+[^\n|]+)\n)+(\n*)/g, function(match, p1, p2) { // replaces with table
        var ret = p1 + "";
        var rows = [];
        var rowAlign = [];
        var array = match.split(/\n/g);
        for(var i = 0; i < array.length; i++) {
            if(array[i]) {
                rows.push(array[i]);
            }
        }

        ret += "<table>";
        var madeHeader = false;
        var oddRow = true;
        for(i = 0; i < rows.length; i++) {
            var items = rows[i].split("|");
            if(!madeHeader) {
                ret += "<thead><tr><th>" + items.join("</th><th>") + "</th></tr></thead>";
                ret += "<tbody>";
                madeHeader = true;
            } else {
                if(items[0] === ":L" || items[0] === ":C" || items[0] === ":R") {
                    for(j = 0; j < items.length; j++) {
                        rowAlign.push(items[j].replace(/:/g, "_"));
                    }
                } else {
                    ret += "<tr class='" + (oddRow ? "darkRow" : "lightRow") + "'>";
                    for(j = 0; j < items.length; j++) {
                        ret += "<td class='" + (rowAlign[j] ? rowAlign[j] : "") + "'>" + items[j].trim() + "</td>";
                    }
                    ret += "</tr>";
                    oddRow = !oddRow;
                }
            }
        }
        ret += "</tbody></table>";
        ret += p2 + "\n";
        return ret;
    });

    // -- Block 14 -- //
    text = text.replace(/(^|\n)((?: ?[-*+]+ .+\n+)+)/g, function(match, p1, p2) { // replaces with unordered list
        var ret = p1 + "";
        var rows = p2.split(/\n/g);

        ret += "<ul>";
        for(var i = 0; i < rows.length; i++) {
            var row = rows[i];
            if(row) {
                var prefix = row.match(/^ ?[-*+]+ /g);
                if(prefix) {
                    var size = prefix[0].trim().length;
                    if(size > 1) {
                        for(j = 1; j < size; j++) {
                            ret += "<ul><li>";
                        }
                        ret += row.replace(/^ ?[-*+]+ /g, "");
                        for(j = 1; j < size; j++) {
                            ret += "</li></ul>";
                        }
                    } else {
                        ret += "<li>" + row.replace(/^ ?[-*+]+ /g, "") + "</li>";
                    }
                }
            }
        }
        ret += "</ul>";

        // combine adjacent uls
        ret = ret.replace(/<\/ul>(\s*)<ul>/g, "$1");
        ret = ret.replace(/<li>(\s*)<ul>/g, "$1<ul>");
        ret = ret.replace(/<\/ul>(\s*)<\/li>/g, "</ul>$1");

        return ret + "\n\n";
    });

    // -- Block 11 -- // // replaces \n with space
    text = text.replace(/(^|[^\n])\n(?!\n|<)/g, "$1 ");

    // -- Block 12 -- // // changed to supbstitute on single \n instead of double
    /*text = text.replace(/^(.*)(\n)/g, "<p>$1</p>$2"); // creates paragraphs
    regex = /(\n)(?!<pre><code>|<p>)(.*)(\n)/g;
    while(regex.test(text)) {
        text = text.replace(regex, "$1<p>$2</p>$3");
    }
    text = text.replace(/(\n)(.*)\n?$/g, "$1<p>$2</p>");
    text = text.replace(/<p><\/p>/g, "<p>&nbsp;</p>"); // adds space to empty strings*/
    text = text.replace(/^(.*)(\n\n)/g, "<p>$1</p>$2"); // creates paragraphs
    regex = /(\n\n)(?!<pre><code>|<p>)(.*)(\n{1,2})/g;
    while(regex.test(text)) {
        text = text.replace(/(\n\n)(?!<pre><code>|<p>)(.*)(\n{1,2})/g, "$1<p>$2</p>$3");
    }
    text = text.replace(/(\n\n)(.*)\n?$/g, "$1<p>$2</p>");

    // -- Block 13 -- //
    // get rid of excess <p>
    text = text.replace(/<p>(\s*)<table>/g, "$1<table>"); // around tables
    text = text.replace(/<\/table>(\s*)<\/p>/g, "</table>$1");
    text = text.replace(/<p>(\s*)<blockquote>/g, "$1<blockquote>"); // around blockquotes
    text = text.replace(/<\/blockquote>(\s*)<\/p>/g, "</blockquote>$1");
    text = text.replace(/<p>(\s*)<h1>/g, "$1<h1>"); // around h1
    text = text.replace(/<\/h1>(\s*)<\/p>/g, "</h1>$1");
    text = text.replace(/<p>(\s*)<hr\/>(\s*)<\/p>/g, "$1<hr>$2"); // around hr (line)

    return text;
}

export function markdown_remove(text) {
    text = markdown_escapeHtml(text); // escape characters
    text = text.replace(/\\\*/g, "&#42;"); // *
    text = text.replace(/\\~/g, "&#126;"); // ~
    text = text.replace(/\\\^/g, "&#94;"); // ^
    text = text.replace(/\\`/g, "&#96;"); // `
    text = text.replace(/\\\(/g, "&#40;"); // (
    text = text.replace(/\\\)/g, "&#41;"); // )
    text = text.replace(/\\\|/g, "&#124;"); // |
    text = text.replace(/\\\?/g, "&#63;"); // ?

    text = text.replace(/`/g, ""); // replaces `.` with code
    var regex = /(\n)\?code\n([^]*)\n\?code(\n)/g;
    while(regex.test(text)) {
        text = text.replace(regex, "$1$2$3"); // replaces 4 spaces with code
    }

    regex = /(\n)>(.*)(\n)/g;
    while(regex.test(text)) {
        text = text.replace(/(\n)>(.*)(\n)/g, "$1$2$3"); // replaces >. with quote
    }

    for(var i = 6; i >= 1; i--) {
        regex = new RegExp("(\\n)\\#{" + i + "}(.*)(\\n)", "g");
        while(regex.test(text)) {
            text = text.replace(regex, "$1$2$3"); // replaces #. with header
        }
    }

    text = text.replace(/\[(.+)\]\(([^\s]+)\)/g, function(match, p1, p2){ // replaces []() with link
        var ret = p1.replace(/\./g, "&#46;"); // changes periods so the same link won't get caught in next replace
        return ret;
    });

    text = text.replace(/(\n)\*{5,}(\n)/g, "$1$2"); // replaces ***** with line

    text = text.replace(/\*\*([^*]+)\*\*/g, "$1"); // replaces **.** with bold
    text = text.replace(/\*([^*]+)\*/g, "$1"); // replaces *.* with italics
    text = text.replace(/~~([^~]+)~~/g, "$1"); // replaces ~~.~~ with strikethrough

    regex = /\^/g;
    while(regex.test(text)) {
        text = text.replace(/\^/g, ""); // replaces ^. with superscript
    }
    //text = text.replace(/\*([^*]*)\*/g, "<sub>$1</sub>"); // replaces *.* with subscript

    text = text.replace(/(\n)(?:(?:(?:[^\n|]+\|)+[^\n|]+)\n)+(\n*)/g, ""); // replaces with table

    text = text.replace(/(^|[^\n])\n(?!\n|<)/g, "$1 ");

    return text;
}

function markdown_escapeHtml(text) {
    var map = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '>', // &gt;
        '"': '&quot;',
        "'": '&#039;'
    };

    return text.replace(/[&<>"'](?!#)/g, function(m) { return map[m]; });
}
