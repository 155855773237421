import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import classNames from 'classnames';
import './app-layout.scss';

class AppLayout extends Component {
    static propTypes = {};

    render() {
        var { children } = this.props;
        return (
            <div className='appLayout'>
                {children}
            </div>
        );
    }
}

export default AppLayout;
