import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Home, AppLayout } from './components';

const exactPaths = [
    { path: '/home', component: Home },
    { path: '/', component: Home },
    // { path: '/note', component: About },
    // { path: '/directory', component: CreateAccount },
];

const matchPaths = [
    // { path: `/directory/:articleId`, component: Article },
    // { path: `/d/:articleId`, component: Article },
    // { path: `/note/:articleId`, component: Article },
    // { path: `/n/:articleId`, component: Article },
];

function AppRouter({ history }) {
    return (
        <AppLayout>
            <Router history={history}>
                <Switch>
                    {exactPaths.map(({ component, path }) => (
                        <Route exact component={component} key={path} path={path} />
                    ))}
                    {matchPaths.map(({ component, path }) => (
                        <Route component={component} key={path} path={path} />
                    ))}
                    <Redirect to='/' />
                </Switch>
            </Router>
        </AppLayout>
    );
}

AppRouter.propTypes = {
    history: PropTypes.object.isRequired,
};

export default AppRouter;
