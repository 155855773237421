import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import classnames from 'classnames';
// import dateFormat from 'dateformat';
import { ajax } from '../../utils/ajax-utils';
import { markdown_format } from '../../utils/markdown';
import './home.scss';

var defaultText;

class Home extends Component {
    static propTypes = {};

    state = {
        slider: 'view',
        text: defaultText,
        user: null,
    };

    componentDidMount() {

    }

    viewEditSwitch(item) {
        var { slider } = this.state;
        this.setState({ slider: slider === 'view' ? 'edit' : 'view' });
    }

    setText(event) {
        this.setState({ text: event.target.value });
    }

    login() {
        var { user } = this.state;

        if (!user) {
            ajax({
                endPoint: '/login',
                data: { username: 'stevensvs5', password: 'textEditTest' },
                success(results) {
                    console.log(results);
                },
            });
        }
    }

    getChildren() {
        ajax({
            endPoint: '/getChildren',
            data: { folderId: 1 },
            success(results) {
                console.log(results);
            },
        });
    }

    render() {
        var { slider, text } = this.state;

        var sliderClass = classnames({ right: slider === 'edit' });
        var editorClass = classnames('noScrollBar', { hidden: slider !== 'edit' });
        var viewerClass = classnames('noScrollBar', { hidden: slider !== 'view' });

        return (
            <div id='thepage' className='home'>
                <div id='navBar'>
                    <div id='path'></div>
                    <div id='loginName'></div>
                    <div id='loginButton' onClick={() => this.login()}>Login</div>
                    <div onClick={() => this.getChildren()}>Get Children</div>
                </div>

                <div id='menuBar'>
                    <div id='viewEditSwitch'>
                        <span style={{ left: '25%' }} onClick={() => this.viewEditSwitch()}>View</span>
                        <span style={{ left: '75%' }} onClick={() => this.viewEditSwitch()}>Edit</span>
                        <div id='switchSlider' className={sliderClass}/>
                    </div>
                </div>
                <textarea
                    id='editor'
                    className={editorClass}
                    wrap='on'
                    onChange={(event) => this.setText(event)}
                    value={text}
                />
                <div id='viewerContainer' className={viewerClass}>
                    <div id='viewer' className='markdown' dangerouslySetInnerHTML={{ __html: markdown_format(text) }} />
                </div>
            </div>
        );
    }
}

export default Home;

defaultText = `Hello Internet User! :)

"I am a simple text editor. I am designed to be simple, fast and easy to use. Here you can write plain text or you can use the Markdown syntax (if you need help with this, just click the "**Markdown Help**" button above).

If you made an account, you can create folders and save files to your account (**Click "Home" to access your folders and files**). If you are a guest, then you can use the download button to download plain text files.

That's it. Just click here and start typing. The name of the file is above as well as the current folder. You can show and hide this stuff in the settings.`;
